import React from "react";

import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

import {LoginMenu} from "./api-authorization/LoginMenu";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };

  const { routes, logo } = props;

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        
        {/* Brand */}
        {logo ? (
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img pt-0"
              src={logo.imgSrc}
            />
        ) : null}

        {/* Toggler */}
        <button
            className="navbar-toggler"
            type="button"
            onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          {/*<Form className="mt-4 mb-3 d-md-none">*/}
          {/*  <InputGroup className="input-group-rounded input-group-merge">*/}
          {/*    <Input*/}
          {/*      aria-label="Search"*/}
          {/*      className="form-control-rounded form-control-prepended"*/}
          {/*      placeholder="Search"*/}
          {/*      type="search"*/}
          {/*    />*/}
          {/*    <InputGroupAddon addonType="prepend">*/}
          {/*      <InputGroupText>*/}
          {/*        <span className="fa fa-search" />*/}
          {/*      </InputGroupText>*/}
          {/*    </InputGroupAddon>*/}
          {/*  </InputGroup>*/}
          {/*</Form>*/}
          {/* Navigation */}
          <Nav navbar>
            {createLinks(routes)}

            <li className="nav-item user-section">
              <LoginMenu>
              </LoginMenu>
            </li>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
