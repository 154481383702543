import React from "react";

// reactstrap components
import { Container} from "reactstrap";

const Header = () => {
    return (
        <>
            <Container fluid>
                <div className="header-body">
                    <img
                        className="logo"
                        alt="Aenon Technologies"
                        src={
                            require("../assets/img/brand/logo.png")
                        }
                    />
                </div>
            </Container>
        </>
    );
};

export default Header;
