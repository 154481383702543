import React, {Fragment, useEffect, useState} from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';

const LoginMenu = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userName, setUserName] = useState(null);
    
    const populateState = async () => {
        const [isAuth, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
        setIsAuthenticated(isAuth);
        setUserName(user && user.name);
    }

    const authenticatedView = (userName, profilePath, logoutPath) => {
        return (<Fragment>
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-light" to={profilePath}>Hello {userName}</NavLink>*/}
            {/*</NavItem>*/}
            <span className="text-light" style={{marginTop: 4}}>Hello {userName}</span>
            <NavItem>
                <NavLink tag={Link} className="text-light" to={logoutPath}>Logout</NavLink>
            </NavItem>
        </Fragment>);

    }

    const anonymousView = (registerPath, loginPath) => {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-light" to={registerPath}>Register</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-light" to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }

    useEffect(() => {
        let _subscription = authService.subscribe(() => populateState());
        populateState().then(() => { });
        
        return () => {
            authService.unsubscribe(_subscription);
        }
    })
    if (!isAuthenticated) {
        const registerPath = `${ApplicationPaths.Register}`;
        const loginPath = `${ApplicationPaths.Login}`;
        return anonymousView(registerPath, loginPath);
    } else {
        const profilePath = `${ApplicationPaths.Profile}`;
        const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
        return authenticatedView(userName, profilePath, logoutPath);
    }
}

export { LoginMenu }
