import {Component} from 'react';
import authService from "./api-authorization/AuthorizeService";

export default class Base extends Component {
    get stateProperties() { return {}};
    
    constructor(props) {
        super(props);
        
        this.state = Object.assign({
            initialData: {},
            selectedInterval: null,
            selectedIntervalId: -1,
            pageLoading: true
        }, this.stateProperties);
    }

    componentDidMount() {
        this.populatePageData();
    }

    async populatePageData() {
        const token = await authService.getAccessToken();
        const url = 'pageData';

        const response = await fetch(url, {
            headers: !token ? {} : {'Authorization': `Bearer ${token}`}
        });
        const data = await response.json();
        if (data.Devices == null) data.Devices = [];

        const selectedInterval = data.TimeRanges[0];
        const selectedIntervalId = selectedInterval.id;

        this.setState({
            initialData: data,
            selectedInterval: selectedInterval,
            selectedIntervalId: selectedIntervalId,
            pageLoading: false
        });
    }
}
