import React from 'react';
import {Card, CardBody, CardTitle, CardSubtitle, Form, Label, FormGroup, Container, Button, Progress, Row, Col} from 'reactstrap'
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
import authService from "./api-authorization/AuthorizeService";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import Base from "./Base";
import moment from 'moment'
import {getTimeZoneName} from '../utils';

const chartOuterContainer = {
    width: '100%'
}

const chartInnerContainer = {
    margin: "0 auto", 
    width: "100%"
}

const alignCenter = {
    textAlign: "center"
}

const datesHeader = {
    textAlign: "center",
    fontSize: 'small'
}

const nextPrevButtons = {
    fontSize: 'small', 
    padding: 4
}

export default class Charts extends Base {
    dateFormat = 'MMM DD, yyyy hh:mm a';
    
    get stateProperties() { 
        return {
            selectedDeviceId: '',
            selectedDevice: null,
            selectedChartId: -1,
            selectedChart: null,
            chartData: [],
            chartLoading: null,
            selectedDate: '',
            endDate: '',
        } 
    };

    componentDidMount() {
        super.componentDidMount();
    }

    handleDateChange(value) {
        if (value === '') {
            this.setState({
                chartLoading: null,
                selectedDate: '',
                endDate: ''
            });
            return;
        }
        const selectedDate = moment(value);
        const endDate = moment(value).add(this.state.selectedInterval.interval, 'm');
        
        this.setState({
            chartLoading: null,
            selectedDate: selectedDate,
            endDate: endDate
        });
    }
    
    handleNextPrev(incrementMins) {
        this.setState({
            chartLoading: null,
            selectedDate: this.state.selectedDate.add(incrementMins, 'm'),
            endDate: this.state.endDate.add(incrementMins, 'm')
        });

        this.loadChart();
    }
    
    handleDeviceChange(s) {
        let selectedDevice = null,
        selectedChartId = -1,
        selectedChart = null;
        
        if (s.target.value != '' && s.target.value != null) {
            selectedDevice = this.state.initialData.Devices.find(d => d.deviceId == s.target.value);
        }
        
        if (selectedDevice != null && selectedDevice.chartConfigs != null && selectedDevice.chartConfigs.length == 1) {
            selectedChart = selectedDevice.chartConfigs[0];
            selectedChartId = selectedChart.id;
        }
        
        this.setState({
            chartLoading: null,
            selectedDeviceId: s.target.value,
            selectedDevice: selectedDevice,
            selectedChartId: selectedChartId,
            selectedChart: selectedChart
        })
    }

    handleIntervalChange(s) {
        let selectedInterval = null;
        
        if (s.target.value != '' && s.target.value != null) {
            selectedInterval = this.state.initialData.TimeRanges.find(d => d.id == s.target.value);
        }
        
        this.setState({
            chartLoading: null,
            selectedIntervalId: s.target.value,
            selectedInterval: selectedInterval,
            selectedDate: '',
            endDate: ''
        });
    }

    handleChartChange(s) {
        let selectedChart = null;
        
        if (this.state.selectedDevice != null && s.target.value != -1 && s.target.value != null) {
            selectedChart = this.state.selectedDevice.chartConfigs.find(d => d.id == s.target.value);
        }
        
        this.setState({
            chartLoading: null,
            selectedChartId: s.target.value,
            selectedChart: selectedChart
        })
    }
    
    isDataValid() {
        return this.state.selectedDevice != null && this.state.selectedDate !== '' && this.state.selectedChartId !== -1;
    }
    
    loadChart() {
        this.populateData();
    }
    
    render() {
        const formatTwoDigits = n => n.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        
        const getTime = (e) => {
            const dt = new Date(e.timeOfDay);
            return `${formatTwoDigits(dt.getHours())}:${formatTwoDigits(dt.getMinutes())}`;
        };
        
        const card = () => <Card>
            <CardBody style={{ padding: 20}}>
                <CardTitle tag="h3">Filter</CardTitle>
                <CardSubtitle
                    className="mb-2 text-muted"
                    tag="h5">
                    Select the device and dates to filter the chart data.
                </CardSubtitle>

                <Card>
                    <CardBody>
                        <Form inline>
                            <FormGroup>
                                <Label className="mr-sm-2">Device:</Label>

                                <select className="form-control" onChange={s => this.handleDeviceChange(s)} value={this.state.selectedDeviceId}>
                                    <option value=''> -- Select -- </option>
                                    {this.state.initialData.Devices.map(d => <option key={d.deviceId} value={d.deviceId}>{d.deviceName}</option>)}
                                </select>
                            </FormGroup>


                            {
                                this.state.selectedDevice != null &&  this.state.selectedDevice.chartConfigs.length > 1 &&
                                <FormGroup className="ml-md-0 ml-lg-5 mt-sm-2 mt-lg-0 mt-0">
                                    <Label className="mr-sm-2">Chart:</Label>
    
                                    <select className="form-control" onChange={s => this.handleChartChange(s)} value={this.state.selectedChartId}>
                                        <option value='-1'> -- Select -- </option>
                                        {this.state.selectedDevice.chartConfigs.map(d => <option key={d.id} value={d.id}>{d.name}</option>)}
                                    </select>
                                </FormGroup>
                            }
                            
                            <FormGroup className="ml-md-0 ml-lg-5 mt-sm-2 mt-lg-0 mt-0">
                                <Label className="mr-sm-2">Date:</Label>

                                {this.state.selectedDate !== '' && <Datetime style={{
                                    width: 300
                                }} timeFormat={true} value={this.state.selectedDate} utc={false} onChange={d => this.handleDateChange(d)}/>}

                                {this.state.selectedDate === '' && <Datetime style={{
                                    width: 300
                                }} timeFormat={true} utc={false} onChange={d => this.handleDateChange(d)}/>}
                            </FormGroup>

                            <FormGroup className="ml-md-0 ml-lg-3 mt-sm-2 mt-lg-0 mt-0">
                                <Label className="mr-sm-2">Interval:</Label>

                                <select className="form-control" onChange={s => this.handleIntervalChange(s)} value={this.state.selectedIntervalId}>
                                    {this.state.initialData.TimeRanges.map(d => <option key={d.id} value={d.id}>{d.title}</option>)}
                                </select>
                            </FormGroup>
                            
                            <Button className="ml-md-2 ml-sm-2 ml-lg-5 mt-sm-2 mt-lg-0 mt-0" color='primary' onClick={() => this.loadChart()} disabled={!this.isDataValid()}>Load Data Chart</Button>
                        </Form>
                    </CardBody>
                </Card>
            </CardBody>
        </Card>;
        
        const lineChart = () => this.state.selectedDevice != null && this.state.selectedChart != null && 
            <LineChart
                data={this.state.chartData}
                margin={{
                    top: 20,
                    right: 10,
                    left: 10,
                    bottom: 20
                }}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={getTime} label={{value: 'Time of day', offset: -15, position: 'insideBottom'}}/>
                <YAxis label={{value: this.state.selectedChart.yAxisLabel, angle: -90, position: 'middle'}}/>
                <Tooltip/>
                {this.state.selectedChart.fields.map(c => <Line type="monotone" dataKey={c.key} stroke={c.color} strokeWidth={2}/>)}
            </LineChart>;
        
        const chart = () => <div>
            <h4 style={alignCenter}>Device:</h4>
            <h5 style={alignCenter}>{this.state.selectedDevice.deviceId}</h5>
            <div style={datesHeader}>
                <b>{this.state.selectedDate.format(this.dateFormat)}</b> - <b>{this.state.endDate.format(this.dateFormat)}</b><br />
                ({getTimeZoneName()})
            </div>
            <div style={datesHeader} className='mt-2'>
                <Button color='secondary' outline style={nextPrevButtons} onClick={() => this.handleNextPrev(-this.state.selectedInterval.interval)}>Prev. { this.state.selectedInterval.title }</Button>
                <Button color='secondary' outline style={nextPrevButtons} className='ml-2' onClick={() => this.handleNextPrev(this.state.selectedInterval.interval)}>Next {this.state.selectedInterval.title}</Button>
            </div>
            
            <ResponsiveContainer width='100%' height={500}>
                {lineChart()}
            </ResponsiveContainer>
        </div>;

        const renderInternal = () => {
            return (
                <>
                    {card()}

                    <div style={chartOuterContainer} className='mt-4'>
                        <div style={chartInnerContainer}>
                            {this.state.chartLoading === true && <Progress animated value={100} />}
                            {this.state.chartLoading === false && chart()}
                        </div>
                    </div>
                </>
            );
        }


        let contents = this.state.pageLoading
            ? <p><em>Loading...</em></p>
            : renderInternal();

        return (
            <div className="content-body">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            {/*<h1 id="tabelLabel">User's Live Energy Consumption Report</h1>*/}
                            <h1 id="tabelLabel">Facility Energy and Environmental Data</h1>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md={12}>
                            {contents}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    async populateData() {
        this.setState({chartLoading: true});
        
        const token = await authService.getAccessToken();
        
        // Make sure date is ISO String, ex: "2016-11-19T12:00:00.000Z"
        const url = `devices?deviceId=${this.state.selectedDevice.deviceId}&startDate=${encodeURIComponent(this.state.selectedDate.format())}&endDate=${encodeURIComponent(this.state.endDate.format())}`;
        const response = await fetch(url, {
            headers: !token ? {} : {'Authorization': `Bearer ${token}`}
        });
        
        const data = await response.json();

        this.setState({
            chartData: data,
            chartLoading: false
        });
    }
    
    TestData() {
        return [{
            "deviceType": 8,
            "energyWatts": 6.0,
            "batteryLevel": 0,
            "humidity": 33.94,
            "panId": "b9, 56",
            "lampState": 0,
            "dimLevel": 0,
            "luxLevel": "0, 0",
            "luxRange": "0, 0",
            "voltage": 121.7,
            "current": 0.0,
            "powerFactor": "0, 0",
            "temperature": 27.65,
            "temperatureFormat": 255,
            "temperatureSetpoint": 0.0,
            "temperatureDifferential": 0.0,
            "lockState": 0,
            "fanMode": 0,
            "errorCode": 0,
            "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
            "timeOfDay": "2023-03-05T00:00:00+00:00"
        }, {
            "deviceType": 8,
            "energyWatts": 6.0,
            "batteryLevel": 0,
            "humidity": 33.94,
            "panId": "b9, 56",
            "lampState": 0,
            "dimLevel": 0,
            "luxLevel": "0, 0",
            "luxRange": "0, 0",
            "voltage": 121.7,
            "current": 0.0,
            "powerFactor": "0, 0",
            "temperature": 27.65,
            "temperatureFormat": 255,
            "temperatureSetpoint": 0.0,
            "temperatureDifferential": 0.0,
            "lockState": 0,
            "fanMode": 0,
            "errorCode": 0,
            "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
            "timeOfDay": "2023-03-05T00:30:00+00:00"
        }, {
            "deviceType": 8,
            "energyWatts": 6.0,
            "batteryLevel": 0,
            "humidity": 33.94,
            "panId": "b9, 56",
            "lampState": 0,
            "dimLevel": 0,
            "luxLevel": "0, 0",
            "luxRange": "0, 0",
            "voltage": 121.7,
            "current": 0.0,
            "powerFactor": "0, 0",
            "temperature": 27.65,
            "temperatureFormat": 255,
            "temperatureSetpoint": 0.0,
            "temperatureDifferential": 0.0,
            "lockState": 0,
            "fanMode": 0,
            "errorCode": 0,
            "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
            "timeOfDay": "2023-03-05T01:00:00+00:00"
        },
            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T01:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T02:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T02:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T03:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T03:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T04:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T04:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T05:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T05:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T06:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T06:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T07:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T07:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T08:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T08:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T09:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T09:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T10:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T10:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T11:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T11:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T12:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T12:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T13:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T13:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T14:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T14:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T15:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T15:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T16:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T16:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T17:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T17:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T18:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T18:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T19:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T19:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T20:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T20:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T21:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T21:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T22:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T22:30:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T23:00:00+00:00"
            },

            {
                "deviceType": 8,
                "energyWatts": 6.0,
                "batteryLevel": 0,
                "humidity": 33.94,
                "panId": "b9, 56",
                "lampState": 0,
                "dimLevel": 0,
                "luxLevel": "0, 0",
                "luxRange": "0, 0",
                "voltage": 121.7,
                "current": 0.0,
                "powerFactor": "0, 0",
                "temperature": 27.65,
                "temperatureFormat": 255,
                "temperatureSetpoint": 0.0,
                "temperatureDifferential": 0.0,
                "lockState": 0,
                "fanMode": 0,
                "errorCode": 0,
                "deviceId": "76, af, 3c, e, 0, 6f, d, 0",
                "timeOfDay": "2023-03-05T23:30:00+00:00"
            }
        ];
    }
}