import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

const Footer = ({ isFixed }) => {
  return (
    <footer className={"footer" + (isFixed ? " fixed": "")}>
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left">
            <span className="title">Aenon Technologies</span><br />
            <span>Phone: +1 (647) 956 9072</span><br />
            <span>Email: <a className="link-white link-shadow" href="mailto:support@aenontechnologies.com" title="Contact Aenon Support">support@aenontechnologies.com</a></span>
          </div>
        </Col>

        <Col xl="6">
          <div className="nav-footer justify-content-center justify-content-xl-end justify-content-md-end text-xl-right">
            
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
