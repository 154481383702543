import React, {useState} from 'react';
import { Collapse, Container, Navbar, NavbarToggler } from 'reactstrap';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';

const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }
  
  return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white mb-3" light>
          <Container>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse className="mt-5 mt-md-0" style={{
              marginLeft: -35,
              minWidth: 90
            }} isOpen={!collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                {/*<NavItem>*/}
                {/*  <NavLink tag={Link} className="text-light" to="/">Home</NavLink>*/}
                {/*</NavItem>*/}
                <LoginMenu>
                </LoginMenu>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
  )
}

export { NavMenu }
