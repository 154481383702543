import React, {useState} from 'react';
import {
    Carousel,
    CarouselControl,
    CarouselIndicators, 
    CarouselItem
} from 'reactstrap';

import './ImagesCarousel.css'

const INTERVAL = 5000;

const items = [
    {
        src: "/images/carousel1.jpg",
        description: (
            <>
                Significantly <span className="blue">reduce energy consumption</span> of street lights while also significantly lowering operating costs,
                light pollution, helping cities reach their net-zero targets.
            </>
        ),
        isLongText: false
    },
    {
        src: '/images/carousel2.jpg',
        description: (
            <>
                As much as <span className="blue">90% drop</span> in energy use is in parking garages.<br />
                Installation is <span className="blue">plug and play</span>. No need for expensive wiring harnesses.
            </>
        ),
        isLongText: false
    },
    {
        src: '/images/carousel3.jpg',
        description: (
            <>
                <span className="blue">Real-time visibility</span> of energy demand and consumption in industrial and commercial facilities.<br />
                Facility Managers are better able to manage energy use as well as participate in energy efficiency program available to businesses.
            </>
        ),
        isLongText: true
    }
];

const ImagesCarousel = () => {
    let animating = false;

    const [activeIndex, setActiveIndex] = useState(0);

    const onExiting = () => animating = true;
    const onExited = () => animating = false;

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={onExiting}
                onExited={onExited}
                tag='div'
                key={item.src}>
                <div
                    className="carousel-item-element"
                    style={{
                        backgroundImage:
                            "url(" +
                            item.src +
                            ")"
                    }}>

                    <div className="cover">
                    </div>

                    <div className={`text-cover ${item.isLongText ? 'long-text' : ''}`}>
                        <span className="item-text">
                            {item.description}
                        </span>
                    </div>
                </div>
            </CarouselItem>
        );
    });
    
    setTimeout(() => {
        //Kinda hack because the carousel not playing automatically is a default behavior
        document.getElementsByClassName('carousel-control-next')[0].dispatchEvent(new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        }));
    }, INTERVAL);
    
    return (
        <div className="carousel-wrapper">
            <Carousel
                interval={INTERVAL}
                activeIndex={activeIndex}
                next={next}
                previous={previous}>
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex}/>
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous}/>
                <CarouselControl direction="next" directionText="Next" onClickHandler={next}/>
            </Carousel>
        </div>
    )
}

export default ImagesCarousel;