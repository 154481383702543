import React, {useState} from "react";
import { Link } from "react-router-dom";
import {NavMenu} from "./NavMenu";
import authService from './api-authorization/AuthorizeService'
import Header from "./Header";
// reactstrap components
import {
  Navbar,
  Container
} from "reactstrap";

const NavBar = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  authService.isAuthenticated().then((v) => {
    setIsAuthenticated(v);
  });
  
  return (
    <>
      <div className={"header bg-gradient-info pb-1 pt-1" + (isAuthenticated ? " logged-in" : "")}>
        <Header />
      </div>

        <Navbar className={"navbar-top" + (isAuthenticated ? " logged-in" : "")} id="navbar-main">
            <Container fluid>
                <Link
                    className="h4 mb-0 text-white text-uppercase d-lg-inline-block"
                    to="/"
                >
                    {props.brandText}
                </Link>
                
                <NavMenu />

            </Container>
        </Navbar>
    </>
  );
};

export default NavBar;
