import React, {useState} from 'react';
import { useLocation, Route, Switch } from "react-router-dom";
import Home from './Home';
import AuthorizeRoute from './api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import authService from './api-authorization/AuthorizeService';

import { Container } from 'reactstrap';

import NavBar from "./NavBar";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

import Charts from "./Charts";
import './../custom.css'

import Context from "../utils/context";

const routes = [
    {
        path: "/charts",
        name: "Data Visualization",
        icon: "ni ni-chart-bar-32 text-primary",
        component: Charts
    },
]
const App = (props) => {
    const mainContent = React.useRef(null);
    const location = useLocation();
    
    const [isSideBarVisible, setIsSideBarVisible] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const populateUserState = async () => {
        const isAuth = await authService.isAuthenticated();
        setIsAuthenticated(isAuth);
    }
    
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;

        populateUserState().then();
        
    }, [location]);

    let sideBar = (<></>);
    
    if (isAuthenticated && isSideBarVisible) {
        sideBar = (
            <Sidebar
                {...props}
                routes={routes}
                logo={{
                    innerLink: "/admin/index",
                    imgSrc: require("../assets/img/brand/logo.png"),
                    imgAlt: "...",
                }}
            />
        );
        
        // header = (<div style={{minHeight: 100}}>&nbsp;</div>);
    }
    
    return (
        <>
            {sideBar}
            
            <div className="main-content" ref={mainContent}>
                <NavBar
                    {...props} />

                <Container fluid>
                    <Context.Provider
                        value={{
                            isSideBarVisible: (v) => setIsSideBarVisible(v)
                        }}>
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <AuthorizeRoute path='/charts' component={Charts} />
                            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                        </Switch>
                    </Context.Provider>
                </Container>
            </div>
            <Footer />
        </>
    );
}
export default App;