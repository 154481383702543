import React, {useEffect, useRef, useState} from 'react';
import './PieChartStatic.css'
import {Row, Col, Fade} from "reactstrap";

const PieChartStatic = () => {
    const hiddenRef = useRef();
    const [animate, setAnimate] = useState(false);
    
    useEffect(() => {

        window.addEventListener('scroll', scrollHandler);

        return () => window.removeEventListener('scroll', scrollHandler);

    }, []);

    const scrollHandler = () => {

        if(window.pageYOffset + window.innerHeight >= hiddenRef.current.offsetTop)
            setAnimate(true);
    }

    const desc = (
        <p className={"pie-description"}>
            "AN ENERGY (DEMAND AND CONSUMPTION) <span className={'red'}>MONITORING AND CONTROL SYSTEM</span> UTILIZING ENVIRONMENTAL AND ENERGY SENSORS SUCH AS INSTRUMENT TRANSFORMERS (SMART ELECTRIC METERS), TEMPERATURE AND HUMIDITY SENSORS AS WELL AS ACTUATORS <span className={'red'}>TO DELIVER REAL-TIME DATA FOR PROMPT ACTIONABLE RESPONSES</span>."
        </p>
    );
    
    return (
        <>
            <Row>
                <Col md={12}>
                    <div className='pie-chart-static text-center'>
                        {desc}
                    </div>
                </Col>
            </Row>
            
            <Row>
                <Col md={12}>
                    <Fade in={animate} timeout={700}>
                        <div className="text-center" ref={hiddenRef}>
                            <img className="pie-chart" src={"/images/pie-chart.png"} alt={"AN ENERGY MONITORING AND CONTROL SYSTEM"} />
                        </div>
                    </Fade>
                </Col>
            </Row>
            
        </>
    );
}

export default PieChartStatic;