import React, {useContext} from 'react';
import ImagesCarousel from './ImagesCarousel/ImagesCarousel';
import PieChartStatic from "./PieChartStatic/PieChartStatic";
import {Col, Row} from "reactstrap";

import Context from "./../utils/context";

const Home = () => {
    const context = useContext(Context);
    context.isSideBarVisible(false);
    
    return (
        <>
            <Row>
                <Col>
                    <ImagesCarousel />
                </Col>
            </Row>
            
            <Row>
                <Col>
                    <div style={{ margin: "20px" }}>
                        <PieChartStatic />
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Home;